import { styled } from '@mui/material';
import { Button, TextField } from '@mui/material';

export const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 417,
  height: 499,
  bgcolor: '#161616',
  transparent: '10%',
  border: '1px solid transparent',
  borderRadius: '12px',
  boxShadow: '0px 4px 20px rgba(60, 133, 191, 0.18)',
  p: 4,
  color: 'white',
};
// backgroundImage:
// 'linear-gradient(#161616, #161616),linear-gradient(90deg, #E15096 0%, #AE2CB1 53.12%, #4132CF 100%)',
// border: '1px solid transparent',

export const StyledText = styled('p')(() => ({
  fontSize: 24,
  fontWeight: '800',
  display: 'flex',
  justifyContent: 'center',
  marginBottom: 60,
}));

export const StyledButtonContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

export const StyledFormicError = styled('p')(() => ({
  color: 'red',
  padding: 0,
  margin: 0,
}));

export const TextFieldWrapper = styled(TextField)`
  fieldset {
    border-radius: 10px;
    padding-top: 11px;
    border-color: white;
  }
`;

export const StyledFormButtonNumber = styled(TextFieldWrapper)(() => ({
  caretColor: 'white',
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none',
  },
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
  '& label': {
    color: 'white',
    '&.Mui-focused': {
      color: 'white',
    },
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
}));
export const StyledFormButton = styled(Button)(({ disabled, type }) => ({
  minWidth: 153,
  padding: '15px 0',
  fontWeight: 700,
  fontSize: 17,
  textAlign: 'center',
  textTransform: 'uppercase',
  cursor: 'pointer',
  border: 'none',
  borderRadius: 10,
  color: 'white',
  lineHeight: '1.21',
  backgroundColor: disabled ? '#745c7f' : 'none',
  backgroundImage: disabled
    ? 'rgba(116,92,127, 1)'
    : type === 'submit'
    ? 'linear-gradient(90deg, #E15096 0%, #AE2CB1 53.12%, #4132CF 100%)'
    : 'linear-gradient(90deg, #FFAB45 0%, #FF5B53 53.12%, #F02CD1 100%)',
  marginTop: 50,
}));
