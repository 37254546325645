import React, { useEffect } from 'react';
import { configureChains, createClient, WagmiConfig } from 'wagmi';
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
// import { EthereumClient, w3mProvider } from '@web3modal/ethereum';
import { jsonRpcProvider } from '@wagmi/core/providers/jsonRpc';
import { publicProvider } from '@wagmi/core/providers/public';
import { InjectedConnector } from 'wagmi/connectors/injected';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { AdminRoutes } from './config/routes';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import ResetPassword from './pages/ResetPassword';
import { useAppSelector } from './hooks/useAppSelector';
import { userSelector } from './redux/auth/selectors';
import { LocalStorage } from './utils/LocalStorage';
import Users from './pages/Users';
import NftCollections from './pages/NftCollections';
import NoMatch from './pages/NoMatch';
import Nfts from './pages/Nfts';
import EditProject from './pages/Project';
import Tokens from './pages/Tokens';
import { chains } from './utils/chains';
import Projects from './pages/Projects';
import checkTokenExpiration from './helpers/checkTokenExpiration';

const { provider } = configureChains(chains, [
  jsonRpcProvider({
    rpc: (chain) => ({
      http: chain.rpcUrls.default.http[0],
    }),
  }),
  publicProvider(),
]);

const client = createClient({
  autoConnect: true,
  connectors: [
    new InjectedConnector({ chains }),
    new WalletConnectConnector({
      chains: chains,
      options: { qrcode: true },
    }),
  ],
  provider,
});

// Get token from localstorage

const App: React.FC = () => {
  const user = useAppSelector(userSelector);
  const token = LocalStorage.getToken();

  useEffect(() => {
    checkTokenExpiration();
    const checkInterval = setInterval(checkTokenExpiration, 1000 * 60);

    return () => {
      clearInterval(checkInterval);
    };
  }, [token, user]);

  return (
    <WagmiConfig client={client}>
      <Router>
        <Routes>
          {!user && !token ? (
            <>
              <Route
                path="*"
                element={<Login />}
              />
              <Route
                path={AdminRoutes.SignUp}
                element={<SignUp />}
              />
              <Route
                path={AdminRoutes.ResetPassword}
                element={<ResetPassword />}
              />
            </>
          ) : (
            <>
              <Route
                path={AdminRoutes.ResetPassword}
                element={<ResetPassword />}
              />
              <Route
                path={AdminRoutes.Users}
                element={<Users />}
              />
              <Route
                path={AdminRoutes.Nfts}
                element={<Nfts />}
              />
              <Route
                path={AdminRoutes.NftCollections}
                element={<NftCollections />}
              />
              <Route
                path={AdminRoutes.Tokens}
                element={<Tokens />}
              />
              <Route
                path={AdminRoutes.Projects}
                element={<Projects />}
              />
              <Route
                path="project/:id"
                element={<EditProject />}
              />
              <Route
                path={AdminRoutes.Login}
                element={
                  <Navigate
                    replace
                    to={AdminRoutes.Nfts}
                  />
                }
              />
              <Route
                path={AdminRoutes.SignUp}
                element={
                  <Navigate
                    replace
                    to={AdminRoutes.Nfts}
                  />
                }
              />

              <Route
                path="*"
                element={<NoMatch />}
              />
            </>
          )}
        </Routes>
      </Router>
    </WagmiConfig>
  );
};

export default App;
