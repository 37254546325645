import { LocalStorage } from '../utils/LocalStorage';
import getExpirationDate from './getExpirationDate';

const isTokenExpired = (token: string) => {
  const expirationDate = getExpirationDate(token);
  return expirationDate && expirationDate < new Date();
};

const checkTokenExpiration = () => {
  const token = LocalStorage.getToken();

  if (token && isTokenExpired(token)) {
    // token expired - redirection to the login page
    LocalStorage.clearToken();
    window.location.href = '/';
  }
};

export default checkTokenExpiration;
