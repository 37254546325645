import { styled } from '@mui/material';

import { Color, FontFamily, TextSize } from '../../helpers/themeStyles';

export const CardWrap = styled('table')(() => ({
  width: '100%',
}));

export const TableItemOwnerStyled = styled('td')(({ theme }) => ({
  padding: '14px 11px',
  display: 'flex',
  flexDirection: 'column',
}));
export const TableTitleWrap = styled('tr')(({}) => ({}));
export const TableTitleOwnerWrap = styled('th')(({}) => ({
  padding: '14px 0',
  display: 'flex',
  textAlign: 'left',
}));
export const TableTitleOwnerStyled = styled('th')(({ theme }) => ({
  padding: '14px 0',
  textAlign: 'left',
  marginRight: '10px',
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.SMALL,
  color: Color.WHITE_OPACITY_LIGHT,
}));
export const TableTitleStyled = styled('th')(({ theme }) => ({
  padding: '14px 0',
  textAlign: 'left',
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.SMALL,
  color: Color.WHITE,
}));

export const TableItemWrap = styled('tr')(({ theme }) => ({
  borderTopStyle: 'solid',
  borderTopWidth: '1px',
  borderColor: Color.DARK_PURPLE_OPACITY_LIGHT,
}));

export const TableItemStyled = styled('td')(({ theme }) => ({
  padding: '10px 0',
}));

export const RowWrap = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
}));

export const AvatarWrap = styled('div')({
  minWidth: '64px',
  minHeight: '64px',
  borderRadius: '8px',
  marginRight: '14px',
});

export const IconWrap = styled('div')({
  width: '24px',
  height: '24px',
  borderRadius: '12px',
  marginRight: '8px',
});

export const SmallText = styled('p')(({ theme }) => ({
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.TABLE_SMALL,
  color: Color.WHITE_OPACITY_LIGHT,
  margin: 0,
}));

export const TitleText = styled('p')(({ theme }) => ({
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.SMALL,
  color: Color.WHITE,
  margin: 0,
  fontWeight: '400',
}));

export const PaginationWrap = styled('div')({
  margin: '0 auto',
});

export const SeeMoreBtn = styled('button')({
  display: 'block',
  margin: '32px auto',
  fontFamily: FontFamily.INTER,
  fontSize: TextSize.SMALL,
  fontWeight: '700',
  color: Color.PURPLE,
  background: 'none',
  border: 'none',
  padding: '0',
  cursor: 'pointer',
  outline: 'inherit',
});

export const StyledTitleNoTopics = styled('h2')(() => ({
  fontWeight: 700,
  fontSize: 48,
  color: 'black',
  marginBottom: '4vw',
}));
export const NoTopicsWrapper = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-around',
  alignItems: 'center',
  marginTop: '15vh',
}));
export const ImageBackground = styled('div')(() => ({
  width: 470,
  height: 457,
  backgroundImage: "url('/images/no-topic.webp')",
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
}));
export const NoTopicsTitleWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  alignItems: 'center',
}));
export const MainWrapper = styled('div')(() => ({
  marginTop: '40px',
  height: '93%',
  display: 'grid',
  // gridTemplateColumns: "65% 1fr",
  // gridColumnGap: 40,
}));

export const AllProjectsWrapper = styled('div')(() => ({
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(176, 50, 214, -0.9)',
  // backgroundImage:
  //   'linear-gradient(180deg, rgba(44, 31, 73, 0.6) 0%, rgba(94, 39, 120, 0.6) 100%)',
  overflow: 'auto',
}));
export const StyledTDataAction = styled('div')(() => ({
  marginLeft: '15px',
  flexGrow: 1,
  textAlign: 'right',
  flexBasis: '15%',
}));
export const TitleProjects = styled('p')(() => ({
  fontFamily: 'Nunito, sans-serif',
  color: 'white',
  textTransform: 'uppercase',
  fontWeight: 700,
  margin: '0 0 30px 0',
}));

export const ProjectsList = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
}));
export const ProjectListWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '12px 0',
  height: 55,
  width: '95%',
  // background: '#F9F9F9',
  // borderRadius: 20,
  marginTop: 10,
  color: 'black',
  borderBottom: '1px solid rgba(83, 50, 214, 0.72)',
}));

export const InfoProject = styled('div')(() => ({
  width: 'fit-content',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
}));
export const StatusProject = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  width: 'fit-content',
  height: '100%',
  svg: {
    color: 'black',
    width: '1.5em',
    height: '1.5em',
  },
}));
export const FotoProject = styled('img')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 34,
  height: 34,
  background: '#C4C4C4',
  backgroundSize: 'contain',
  borderRadius: '50%',
  marginLeft: 20,
}));
export const ProjectName = styled('p')(() => ({
  fontFamily: 'Montserrat, sans-serif',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: 16,
  lineHeight: '130%',
  marginLeft: 30,
}));
export const ProjectOwner = styled('p')(() => ({
  fontFamily: 'Montserrat, sans-serif',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: 10,
  lineHeight: '130%',
  color: '#A4A4A4',
  marginRight: 80,
  width: 80,
  textAlign: 'center',
}));
export const CreateProjectsWrapper = styled('div')(() => ({
  position: 'relative',
  width: '100%',
}));
export const TextDeveloping = styled('p')(() => ({
  position: 'absolute',
  top: '50%',
  fontSize: 58,
  left: '50%',
  width: 'fit-content',
  color: 'black',
  transform: 'translate(-50%, -50%)',
}));
