import { styled } from '@mui/material';
import { Button } from '@mui/material';
export const StyledButton = styled(Button)(() => ({
  minWidth: '7vw',
  paddingTop: 4,
  paddingBottom: 4,
  fontWeight: 700,
  fontSize: 16,
  textAlign: 'center',
  textTransform: 'inherit',
  cursor: 'pointer',
  border: 'none',
  borderRadius: 10,
  color: 'white',
  backgroundImage: 'linear-gradient(to right, #808080 30%, #E6EEF7 )',
}));

export const StyledButtonPurple = styled(Button)(() => ({
  marginTop: '8px',
  textTransform: 'uppercase',
  fontWeight: 400,
  borderRadius: '6px',
  fontSize: '15px',
  fontFamily: 'Zen Dots',
  padding: '16px 32px',
  letterSpacing: '0.05em',
  color: '#ffffff',
  background:
    'linear-gradient(90deg, #E15096 0%, #AE2CB1 53.12%, #4132CF 100%)',
}));

export const StyledButtonBlack = styled(Button)(() => ({
  marginTop: '3px',
  textTransform: 'uppercase',
  fontWeight: 400,
  borderRadius: '6px',
  fontSize: '15px',
  fontFamily: 'Zen Dots',
  padding: '10px 28px',
  letterSpacing: '0.05em',
  color: '#B032D6',
  background: '#161616',
  border: `2px solid #B032D6`,
}));
