import * as React from 'react';
import { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import 'react-markdown-editor-lite/lib/index.css';
import { useAppSelector } from '../../hooks/useAppSelector';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import Container from '../../components/Container';
import { StyledTitle, StyledTitleWrapper } from './styles';
import { getProjectByIdThunk } from '../../redux/projects/thunks';
import { selectedProjectSelector } from '../../redux/projects/selectors';
import ProjectPage from '../../components/ProjectPage';

const Topic: React.FC = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const project = useAppSelector(selectedProjectSelector);
  const { id } = params;
  const actionTitle = 'Project Details';

  React.useEffect(() => {
    dispatch(getProjectByIdThunk(id || ''));
  }, [dispatch, id]);

  return (
    <Container>
      <StyledTitleWrapper>
        <StyledTitle>{actionTitle}</StyledTitle>
      </StyledTitleWrapper>

      {project ? <ProjectPage /> : <h1>loading</h1>}
    </Container>
  );
};

export default Topic;
