const getExpirationDate = (token: string) => {
  const tokenParts = token.split('.');
  if (tokenParts.length !== 3) {
    return null;
  }

  try {
    const payload = JSON.parse(
      Buffer.from(tokenParts[1], 'base64').toString('utf-8')
    );
    if (payload && payload.exp) {
      return new Date(payload.exp * 1000);
    }
  } catch (error) {
    console.error('Invalid token format');
  }

  return null;
};

export default getExpirationDate;
